import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 

  background-color: black;

  width: 100%;

  @media (max-width: 48em) {
    svg{
      width: 2vw;
    }
  }

  svg {
    width: 5.5vw;
    padding-right: 50rem;
    padding-bottom: 40rem;
    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;
    g {
    
      path {
        stroke: #fff;
      }
    }

    @media (min-width: 1400px){
      padding-right: 38rem;
      }
      @media (min-width: 1900px){
        padding-right: 50rem;
        }
    
  }
`;

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,

    transition: {
      duration: 2,
      //yoyo: Infinity,
      ease: 'easeInOut',
    },
  },
};
const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      yoyo: Infinity,

      ease: 'easeInOut',
    },
  },
};

const Text = styled(motion.span)`
  font-size: ${(props) => props.theme.fontxxxl};
  color: ${(props) => props.theme.text};
  padding-top: 0.5rem;

  @media (min-width: 1400px){
    font-size: ${(props) => props.theme.fontxxl};
    padding-bottom:10rem;
    }

  @media (max-width: 48em) {
    padding-bottom:60rem;
    font-size: ${(props) => props.theme.fontxxl};

  }
`;

const Loader = () => {
  return (
    <Container
      initial={{ y: 0, opacity: 1 }}
      exit={{ y: '100%', opacity: 0 }}
      transition={{ duration: 2}}
    >
      <div className="svgContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="48px"
        viewBox="0 0 24 24"
        width="48px"
        fill="none"
      >
        <g>
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="M 117.16,116.73
            C 117.26,114.64 117.34,112.59 117.34,110.48M 82.85,117.30
            C 82.81,115.17 82.52,113.13 82.32,111.05M 120.94,71.84
            C 123.04,68.38 124.78,64.68 126.57,61.03
              127.98,58.14 133.93,45.10 137.14,44.00
              137.29,43.95 137.58,46.84 137.59,46.99
              137.97,50.08 139.63,52.71 141.19,55.27
              146.08,63.27 158.58,67.27 166.40,62.18M 81.73,74.12
            C 62.78,43.70 57.08,57.39 34.00,72.35M 126.62,143.44
            C 122.22,152.44 114.94,154.11 105.60,154.81M 109.01,142.31
            C 108.11,147.33 105.85,151.68 103.33,155.94M 90.26,141.17
            C 90.50,146.38 93.14,152.63 94.23,153.67M 68.66,140.60
            C 70.97,151.24 82.50,153.92 91.96,155.94M 84.22,138.95
            C 73.27,142.07 64.64,137.46 62.05,125.71
              60.67,119.42 61.38,110.36 62.28,104.02
              63.28,96.97 64.49,90.20 68.61,84.38
              78.34,70.64 113.91,68.21 125.39,79.81
              136.62,91.14 147.66,135.51 123.81,140.32
              115.50,142.00 106.92,135.62 98.93,134.61
              95.95,134.24 89.15,135.98 88.30,135.98"
          />
        </g>
      </svg>
    </div>
      <Text variants={textVariants} initial="hidden" animate="visible">
        Ant Studios
      </Text>
    </Container>
  );
};

export default Loader;
