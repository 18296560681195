import React from "react";
import styled from "styled-components";
import img1 from "../assets/Images/AnthillLarge.png";
import img2 from "../assets/Images/BrownSoil.jpg";
import img3 from "../assets/Images/AntDivCarry.png";

const Section = styled.section`
  overflow: hidden;
  min-height: 120%;
  width: 100%;
  margin: 0 auto;
  background-image: url(${img2});
  color:white;

  .antCarry { 
    padding-right: 10rem;
  }
 

position: relative;

  display: flex;
  @media (max-width: 48em) {
    width: 90%;
  }

  @media (max-width: 30em) {
    width: 100%;
  }
  /* justify-content: center;
  align-items: center; */
`;

const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 15%;
  margin-right: 10%;
  padding: 2rem;

@media (min-width: 1920px){
  margin-top: 20%;
    }

@media (min-width: 1400px){
  margin-top: 20%;
    }

  @media (max-width: 64em) {
    width: 80%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 2rem;
    width: 70%;
  }
`;

const Right = styled.div`
  width: 50%;
  min-height: 120vh;

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 95%;
    bottom: 10%;
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    top: 30%;
  }
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;

      position: absolute;
      left: 60%;
      bottom: 20%;
    }
    
  }
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:3rem;
    padding:4rem;
    max-width: 40%;
    max-height:15%;
    background-image: url(${img1});
    color: white;
    font-weight: 600;
    outline: 4px solid black;

    @media (min-width: 1920px){
      padding:4rem;
      max-width: 30%;
    }
    @media (max-width: 64em) {
      display: none;
    
    
`;
const CarryContainer = styled.div`
    position: absolute;
    top:25rem;
    right:5rem;
    z-index: 5;

    @media (min-width: 1920px){
      right: 15rem;
    }

    @media (max-width: 64em) {
      display: none;
  
`;
const TextContainerTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  max-width: 40%;
  max-height:10%;
  margin:6rem;
  margin-bottom:10rem;
  z-index: 6;
  position:relative;
  padding-top:4rem;
  padding-bottom:5rem;
  font-weight: 600;
  outline: 4px solid black;

  @media (min-width: 1920px){
    padding:4rem;
    max-width: 30%;
  }

  @media (max-width: 64em) {
    display: none;
    

`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;
  /* text-transform: capitalize; */

  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 5;

  span {
    display: inline-block;
  }

  @media (min-width: 1420px){
  
    left:2%;
    }

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 15%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      <Title
          data-scroll
          data-scroll-speed="-1"
          data-scroll-direction="hoizontal"
      >
        About Us
      </Title>

      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
        Here At Ants Studios, we embody the collaborative and industrious spirit of ants to transform digital 
        landscapes into thriving ecosystems. Our agency draws inspiration from the meticulous teamwork of ants. 
        Just like the intricate tunneling systems beneath an ant hill, we delve deep into the digital realm, crafting 
        unique and multifaceted websites. 
        <br />
        <br />
        Our mission is to be more than just a surface-level presence; we aspire to be 
        the architects of online experiences, combining innovative features into grand masterpieces. Much like ants 
        constantly seeking opportunities, we are relentless scavengers for new challenges, growth, and opportunities, 
        always hungry to perfect our craft. Join us as we redefine digital marketing and website development, one unique project at a time.
        <br />
        <br />
      </Left>

      <Right>
        <TextContainer
         data-scroll
         data-scroll-speed="-1"
         data-scroll-sticky
         data-scroll-direction="horizontal"

        >
          <h1>Unique Digital Solutions:</h1>
          <p>
          We strive to understand each client's unique needs and deliver customized digital solutions that reflect their brand 
          identity and objectives. Our goal is to create websites that stand out in the digital landscape.
          </p>
          </TextContainer>
        <TextContainerTwo
         data-scroll
         data-scroll-speed="-1"
         data-scroll-direction="horizontal"
         
        >
          <h1>Growth & Innovation</h1>
          <p>
            We embrace a culture of constant improvement and innovation. Always be on the lookout for new technologies, 
            design trends, and marketing strategies to ensure that our clients are at the forefront of digital excellence.
          </p>
          </TextContainerTwo>
          <CarryContainer
         data-scroll
         data-scroll-sticky
         data-scroll-speed="-1"
         data-scroll-direction="horizontal"
        >
          <img src={img3} alt="antCarry"
          class="100pxSquare" 
          width = "100%" 
          />
          </CarryContainer>  
        <TextContainer
         data-scroll
         data-scroll-sticky
         data-scroll-speed="-1"
         data-scroll-direction="horizontal"
        >
          <h1>Collaboration</h1>
          <p>
           Foster open and transparent communication with clients throughout the development process. Our aim is to build
           strong, collaborative relationships, ensuring that clients are actively involved and informed at every stage, 
           just like the cohesive teamwork seen in an ant colony.

          </p>
          </TextContainer>

          <CarryContainer
         data-scroll
         data-scroll-sticky
         data-scroll-speed="-1"
         data-scroll-direction="horizontal"
        >
          <img src={img3} alt="antCarry"
          class="100pxSquare" 
          width = "100%" 
          />
          </CarryContainer>  
      </Right>
    </Section>
  );
};

export default About;
