import { motion } from "framer-motion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";


import img1 from "../assets/Images/TacomaSteel.png"
import img2 from "../assets/Images/OneofOneSant.png";


const Section = styled(motion.section)`
  min-height: 100vh;
  height: auto;
  width: 80%; 
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #64351B;
 
  overflow-x: hidden;
  overflow-y: hidden;

  position: relative;
@media (max-width: 48em){
  /* background-color: orange; */
  html, body {
    overflow-x:hidden 
    position:fixed;
  } 
}
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};

  position: absolute;
  top: 2rem;
  left: 5%;
  z-index: 11;



  @media (min-width: 1420px){
    padding-top:0rem;
    left:3%;
    }
    @media (min-width: 1900px){
      left:3%;
      }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
    top: 3rem;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
    top: 3rem;
  }
`;

const Left = styled.div`
  width: 35%;
  background-color: #64351B
  margin-right:5rem;


;
  color: ${(props) => props.theme.text};

  min-height: 100vh;
  z-index: 10;

  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  

  p {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 300;
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: 1420px){
    padding-top:2rem;
  
    }

  @media (max-width: 64em) {
    p {
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  @media (max-width: 48em) {
    width: 50%;
    p {
      font-size: ${(props) => props.theme.fontsm};
    }
  }
  @media (max-width: 30em) {
    p {
      font-size: ${(props) => props.theme.fontxs};
    }
  }

 
`;
const Right = styled.div`
  width: 100%; 
  position: absolute;
  left: 30%;
  padding-left: 30%;
  background-color:#64351B;
  min-height: 100vh;
  color:white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;

  @media (max-width: 64em) {
    width:40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 15rem;
    top: 10rem;
    padding-left: 0;
    padding-top:4rem;
    gap:2rem;
  }

`;

const Item = styled(motion.div)`
  display: inline-block;
  width: 20rem;
  /* background-color: black; */
  margin-right: 6rem;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  h1 {
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: 48em) {
    width: 10rem;
    margin-right: 0rem;
    

  }
`;
//data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal"
const Product = ({ img, title,link = "" }) => {
  return (
    // x: 100, y: -100
    <Item
      initial={{ filter: "grayscale(100%)" }}
      whileInView={{ filter: "grayscale(0%)" }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false, amount: "all" }}
    >
      <img width="400" height="600" src={img} alt={title} />
      <h1>{title}</h1>
      <a href= {link} > Website Link</a>
      
    </Item>
  );
};

const ClientWork = () => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const Verticalref = useRef(null);

  useLayoutEffect(() => {
    let element = ref.current;

    let scrollingElement = Verticalref.current;

    let pinWrapWidth = scrollingElement.offsetWidth;
    let t1 = gsap.timeline();

    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: `${pinWrapWidth} bottom`,
          scroller: ".App", //locomotive-scroll
          scrub: 1,
          pin: true,
          delay: 0.5,
          // markers: true,
          // anticipatePin: 1,
        },
        height: `${scrollingElement.scrollWidth}px`,
        ease: "none",
      });

      t1.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: `${pinWrapWidth} bottom`,
          scroller: ".App", //locomotive-scroll
          scrub: 1,
          delay: 0.5,
          // markers: true,
        },
        x: pinWrapWidth,

        ease: "none",
      });
      ScrollTrigger.refresh();
    }, 1000);
    ScrollTrigger.refresh();

    return () => {
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []);
  

  return (
    <Section ref={ref} id="clientwork" >
      <Title data-scroll data-scroll-speed="-1">
        Client Work
      </Title>
      <Left>
        <p>
        At Ants Studios, our commitment to excellence extends far beyond mere services; 
        it's a dedicated partnership with our clients to bring their visions to life. We view each client collaboration as an opportunity to create something truly exceptional. 
         Just as ants collaborate to build intricate and unique habitats, we work closely with our clients to develop websites that not only meet but exceed expectations.
          <br /> <br />
          Ant Studios thrives on the philosophy that every client is unique, deserving of solutions that reflect their 
          individuality. Join us on a journey where your ideas are not only heard but meticulously crafted into grand digital masterpieces.
        </p>
      </Left>
      <Right data-scroll ref={Verticalref} data-scroll-direction = "vertical" data-scroll-speed="-1" >
        <Product img={img2} title="One of One Sant (Shopoify)" link ={"https://www.oneofonesant.com"} target="_blank"/>
        <Product img={img1} title="Tacoma Steel (In Progress)" />
      </Right>
    </Section>
  );
  };


export default ClientWork;
