import { motion } from 'framer-motion';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, {  useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import img5 from "../assets/Images/AntRock.png";

import img1 from '../assets/Images/Xlogo.png';
import img2 from '../assets/Images/facebooklogo.png';
import img3 from '../assets/Images/Insatgramlogo.png';
import img4 from '../assets/Images/linkedinlogo.png';

const Section = styled.section`
  overflow-x: hidden;
  min-height: 100%;
  /* height: auto; */
  width: 100%;
  margin: 0 auto;
  /* height: 300%; */
  background-color:#64351B;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  /* background-color: ${(props) => props.theme.text}; */
  @media (max-width: 48em){
    height: 100%;
  } 
`;

const SectionTwo = styled.section`
  min-height: 100%;
  width: 100%;
  margin: 10rem auto;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: blue; */
  position: relative;

@media (max-width: 48em){
  width: 90%;
} 

`;

const Overlay = styled.div`
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 40%;
  box-shadow: 0 0 0 1% ${(props) => props.theme.text};
  border: 3px solid black;

  z-index: 11;

  @media (max-width: 70em) {
  width: 40%;

    height: 80%;
  }

  @media (max-width: 64em) {
  width: 50%;
  box-shadow: 0 0 0 0% ${(props) => props.theme.text};
  top:0;
  left:0rem;

    height: 100%;
  }
  @media (max-width: 48em) {

  width: 100%;
  height: 90%;
  top:0;
  left:0rem;
  }

  @media (max-width: 30em) {
  position: absolute;
  width: 80%;
  background-color:#64351B;
  top:10rem;
  left:13.5rem;
  height: 5%;
  }
`;


const Container = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 25%;
  height: auto;
  /* background-color: yellow; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;


  @media (max-width: 64em) {
  width: 30%;


  }
  @media (max-width: 48em) {
  width: 40%;

  }
  @media (max-width: 30em) {
  width: 60%;

  }
`;
const ContainerTwo = styled.div`
  min-height: 100%;
  padding-left: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 64em){
    margin-right:7rem;
    padding:1rem;
    padding-right: 0rem;
}
`;

const Emailbox = styled.div` 
color:white;
margin: 3rem;
text-align: center;

@media (max-width: 64em){
  font-size: ${(props) => props.theme.fontsm};
  margin: 0rem;
}
`;

const Banner = styled.h1`

  font-size: ${(props) => props.theme.fontxl};
  font-family: 'Kaushan Script';
  color: ${(props) => props.theme.text};
  /* position: absolute; */
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  margin-right:15rem;
  padding: 2rem 8rem;
  background-image: url(${img5});


  :nth-child(1) {
  
    
  }
  :nth-child(2) {
    

  }
  :nth-child(3) {
    
  }
  :nth-child(4) {
   
  }

  @media (max-width: 1920px){
    left: 2rem;
}

  @media (max-width: 70em){
    font-size: ${(props) => props.theme.fontxxl};
}

@media (max-width: 64em){
    margin: 1rem 0;
}
 
@media (max-width: 48em){
    font-size: ${(props) => props.theme.fontxl};
    margin: 0.5rem 0;

}
@media (max-width: 30em){
    font-size: ${(props) => props.theme.fontlg};
}

  span{
    display: block;
    padding: 8rem 8rem;
    
    
  }

  #xlogo{
    position: absolute;
    width:10%;
    top:9%;
    right:17%;
    opacity: 0.8;
    
  }

  #facebooklogo{
    position: absolute;
    width:10%;
    top:34%;
    right:17%;
    opacity: 0.8;
  }
  #instagramlogo{
    position: absolute;
    width:20%;
    top:58%;
    right:12%;
    opacity: 0.8;
  }

  #linkedinlogo{
    position: absolute;
    width:10%;
    top:83%;
    right:16%;
    opacity: 0.8;
  }

  @media (min-width: 1920px){
    #xlogo{
    right: 23%;
    }
    #facebooklogo{
      right:23%;
    }
    #instagramlogo{
      right:18%;
    }
    #linkedinlogo{
      right:23%;
    }

}

  @media (max-width: 64em){
    #xlogo{
      width:45%;
      top:10%;
      right:26%;
    }
    #facebooklogo{
      width:45%;
      top:34%;
      right:28%;
    }
    #instagramlogo{
      width:80%;
      top:60%;
      right:8%;
    }
    #linkedinlogo{
      width:45%;
      top:84%;
      right:25%;
    }
  }





`;


const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: 'Kaushan Script';
  font-weight: 300;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 1px ${(props) => props.theme.text};

  position: absolute;
  top: 0rem;
  left: 45rem;
  z-index: 15;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};

    left: -5rem;

  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  
  }
`;


const ContactUs = () => {
   gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const ScrollingRef = useRef(null);




  return (
    <Section  ref={ref} id="fixed-target" className="contact-us">
      <Title
        data-scroll data-scroll-speed="-5" data-scroll-direction="horizontal"
      >
        Contact Us
      </Title>
      
      <Overlay>
      <Emailbox>
      <h2>Email: contact@itsantsstudios.com</h2>
      </Emailbox>
      </Overlay>

      <SectionTwo>
      <ContainerTwo id="direction">
        <ul>
        <Banner>
          <span>
          <a href="https://www.facebook.com/profile.php?id=61554244094012"><img src={img1} id="xlogo"/></a>
          </span>
        </Banner>
        <Banner>
        <span>
        <a href="https://www.linkedin.com/company/antsstudios/"><img src={img2} id="facebooklogo"/></a>
          </span>
        </Banner>
        <Banner>
          <span
          >
           <a href="https://www.instagram.com/antsstudios/"><img src={img3} id="instagramlogo"/></a>
          </span>
        </Banner>
        <Banner>
          <span
       
          >
            <a href="https://www.linkedin.com/company/antsstudios/"><img src={img4} id="linkedinlogo"/></a>
          </span>
        </Banner>
        </ul>
      </ContainerTwo>
      </SectionTwo>
    </Section>
  );
  }

export default ContactUs;
